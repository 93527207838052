import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { APLightbox, APNote } from "@/components";
import { StudentModel } from "@/models";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TeCommon } from "../Common";

export class StudentForm {
  studentid = '';
  name = '';
  phone = '';
  parentName = '';
  parentPhone = '';
  liveCity = '';
  region = '';
  address = '';
  pic = '';
  city = '';
  school = '';
  note = '';
  stid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherStudentInfoController>({
  components: {
    Header, APLightbox, APNote,Footer
  }
})
export default class ApTeacherStudentInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private studentForm = new StudentForm();
  private token = window.localStorage.getItem('teacherToken') as string;

  private lightbox: { showLB: boolean, errorMsg: string } = {
    showLB: false, 
    errorMsg:  '',
  };

  public async created() {
    const item: { [key: string]: string } = {
      stid: this.$route.query.stid as string,
      token: this.token,
    }
    const editData = await StudentModel.teGet(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    TeCommon.checkToken(data.ERR_CODE as number);

    const item = data.info as { [key: string]: string };

    this.studentForm.stid = item.stid;
    this.studentForm.studentid = item.studentid;
    this.studentForm.name = item.name;
    this.studentForm.phone = item.phone;
    this.studentForm.parentName = item.parentName;
    this.studentForm.parentPhone = item.parentPhone;
    this.studentForm.liveCity = item.liveCity;
    this.studentForm.region = item.region;
    this.studentForm.address = item.address;
    this.studentForm.pic = item.pic;
    this.studentForm.city = item.city;
    this.studentForm.school = item.school;
    this.studentForm.note = item.note;
  }

  public openNoteLB() {
    this.$emit("openNote", true, this.studentForm.note);
  }

  public async submitNote(note: string) {
    const item: { [key: string]: string } = {
      stid: this.studentForm.stid,
      note: note,
      token: this.token,
    }
    const errNo = await StudentModel.teEditNote(item);
    switch (errNo) {
      case ErrorCode.InvalidToken:
        TeCommon.logout();
        break;
      case ErrorCode.Success:
        this.doubleClick = false;
        this.$router.go(0); //reload page
        break;
      default:
        this.lightbox.errorMsg = ErrorMessage[errNo];
        this.lightbox.showLB = true;
        this.doubleClick = false;
        break;
    }
  }

  private closeLB() {
      this.lightbox.showLB = false;
  }
}